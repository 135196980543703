<template>
  <v-container fluid>
    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
    >
      <base-material-card
        color="primary"
        icon="mdi-pencil"
        inline
        class="px-5 py-3 mb-7"
      >
        <template v-slot:after-heading>
          <div class="display-1 font-weight-light">{{ getTitulo }}</div>
        </template>
        <v-row class="mx-auto mt-2">
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Alta Tensão"
            >
              <v-text-field
                id="alta-tensao"
                type="number"
                label="Alta Tensão"
                v-model="altaTensao"
                :error-messages="errors"
                step="0.001"
                outlined
              />
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Média Tensão"
            >
              <v-text-field
                id="media-tensao"
                class="ml-4"
                type="number"
                label="Média Tensão"
                v-model="mediaTensao"
                :error-messages="errors"
                step="0.001"
                outlined
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row
          id="importacao-mes"
          class="mx-auto"
        >
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Baixa Tensão"
            >
              <v-text-field
                id="baixa-tensao"
                type="number"
                label="Baixa Tensão"
                v-model="baixaTensao"
                :error-messages="errors"
                step="0.001"
                outlined
              />
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Ramal"
            >
              <v-text-field
                id="ramal"
                class="ml-4"
                type="number"
                label="Ramal"
                v-model="ramal"
                :error-messages="errors"
                step="0.001"
                outlined
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row
          id="extensao-empresa"
          class="mx-auto"
          v-if="!isEdit"
        >
          <v-col cols="12">
            <select-company
              id="fs-empresa-importacao-bdgd"
              :type="'outlined'"
              :selectedCompany="companyIdEdit"
              @companySelected="companySelected"
            />
          </v-col>
        </v-row>
        <v-row class="mx-auto">
          <v-col cols="12">
            <input-date
              label="Mês dos dados"
              :selectedDate="dataBaseEdit"
              @dataSelected="dataSelected"
            />
          </v-col>
        </v-row>
      </base-material-card>
      <v-row id="row-btn-save">
        <v-col cols="12">
          <v-btn
            id="btn-save-relatorio"
            color="primary"
            min-width="100"
            style="float: right"
            class="mt-n5 mr-0"
            @click="salvar()"
            :loading="loading"
            :disabled="invalid || invalido"
          >
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>
  </v-container>
</template>

<script>
import CompanyService from '@/services/CompanyService';
import camposFiltrosMixins from '@/mixins/camposFiltrosMixins';
import InputDate from '@/components/general/InputDate.vue';
import SelectCompany from '@/components/general/SelectCompany.vue';
import routes from '@/store/modules/routes';

export default {
  mixins: [camposFiltrosMixins],

  components: {
    SelectCompany,
    InputDate
  },

  data: () => ({
    titulo: null,
    altaTensao: null,
    mediaTensao: null,
    baixaTensao: null,
    ramal: null,
    dataBase: null,
    companyId: null,
    dataBaseEdit: null,
    companyIdEdit: null,
    loading: false,
    invalido: true,
    nomeCompania: null
  }),

  mounted() {
    if (this.$route.name.includes('Editar'))
      this.getRelatorio(this.$route.params.id);
  },

  methods: {
    getRelatorio(id) {
      CompanyService.getNetworkExtentById(id)
        .then((response) => {
          const data = response.data[0];
          this.altaTensao = data.alta_tensao_km;
          this.mediaTensao = data.media_tensao_km;
          this.baixaTensao = data.baixa_tensao_km;
          this.ramal = data.ramal_km;
          this.dataBaseEdit = data.data_base;
          this.nomeCompania = data.name;
        })
        .catch(() =>
          this.$toast.error('Erro ao recuperar a extensão.', '', {
            position: 'topRight'
          })
        );
    },

    salvar() {
      let id = this.$route.name.includes('Editar') ? this.$route.params.id : 0;

      let data = {
        alta_tensao_km: this.altaTensao,
        media_tensao_km: this.mediaTensao,
        baixa_tensao_km: this.baixaTensao,
        ramal_km: this.ramal,
        data_base: this.dataBase,
        company_id: this.companyId
      };

      data = this.validaCamposBeforeSave(data);

      if (data !== 'invalido') {
        CompanyService.saveNetworkExtent(id, data)
          .then((response) => {
            if (response.data[0] == 'ok') {
              this.$toast.success('Extensão salva com sucesso.', '', {
                position: 'topRight'
              });
              this.$router.replace({
                name: routes.state.appRoutes['EMPRESAS_LISTAR']
              });
            } else {
              this.mostrarMensagensErro(response.data);
            }
          })
          .catch(() => {
            this.$toast.error('Erro ao salvar a extensão.', '', {
              position: 'topRight'
            });
          })
          .finally(() => (this.loading = false));
      }
    },

    validaCamposBeforeSave(data) {
      this.loading = true;

      if (
        data.alta_tensao === null ||
        data.media_tensao === null ||
        data.baixa_tensao === null ||
        data.ramal === null ||
        data.company_id === null ||
        data.data_base === null
      ) {
        this.$toast.warning('É necessário preencher todos os campos.', '', {
          position: 'topRight'
        });
        this.loading = false;
        return 'invalido';
      }

      if (
        data.alta_tensao <= 0 ||
        data.media_tensao <= 0 ||
        data.baixa_tensao <= 0 ||
        data.ramal <= 0
      ) {
        this.$toast.warning(
          'Os valores numéricos devem ser maiores que zero.',
          '',
          {
            position: 'topRight'
          }
        );
        this.loading = false;
        return 'invalido';
      }

      return data;
    },

    mostrarMensagensErro(mensagensErro) {
      for (let erro of mensagensErro) {
        this.$toast.error(erro, '', { position: 'topRight' });
      }
    },

    dataSelected(data) {
      this.dataBase = data;
    },

    companySelected(company) {
      this.companyId = company.id;
    },

    handleVariablesChange() {
      if (this.companyId !== null && this.dataBase !== null)
        this.invalido = false;
    }
  },

  watch: {
    dataBase: 'handleVariablesChange',
    companyId: 'handleVariablesChange'
  },

  computed: {
    getTitulo() {
      return this.isEdit
        ? `Editar Extensão de Rede::${this.nomeCompania}`
        : 'Adicionar Extensão de Rede';
    },
    isEdit() {
      return this.$route.name.includes('Editar');
    }
  }
};
</script>

<style scoped></style>
