var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('base-material-card',{staticClass:"px-5 py-3 mb-7",attrs:{"color":"primary","icon":"mdi-pencil","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-1 font-weight-light"},[_vm._v(_vm._s(_vm.getTitulo))])]},proxy:true}],null,true)},[_c('v-row',{staticClass:"mx-auto mt-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Alta Tensão"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"alta-tensao","type":"number","label":"Alta Tensão","error-messages":errors,"step":"0.001","outlined":""},model:{value:(_vm.altaTensao),callback:function ($$v) {_vm.altaTensao=$$v},expression:"altaTensao"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Média Tensão"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-4",attrs:{"id":"media-tensao","type":"number","label":"Média Tensão","error-messages":errors,"step":"0.001","outlined":""},model:{value:(_vm.mediaTensao),callback:function ($$v) {_vm.mediaTensao=$$v},expression:"mediaTensao"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mx-auto",attrs:{"id":"importacao-mes"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Baixa Tensão"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"baixa-tensao","type":"number","label":"Baixa Tensão","error-messages":errors,"step":"0.001","outlined":""},model:{value:(_vm.baixaTensao),callback:function ($$v) {_vm.baixaTensao=$$v},expression:"baixaTensao"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Ramal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-4",attrs:{"id":"ramal","type":"number","label":"Ramal","error-messages":errors,"step":"0.001","outlined":""},model:{value:(_vm.ramal),callback:function ($$v) {_vm.ramal=$$v},expression:"ramal"}})]}}],null,true)})],1)],1),(!_vm.isEdit)?_c('v-row',{staticClass:"mx-auto",attrs:{"id":"extensao-empresa"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('select-company',{attrs:{"id":"fs-empresa-importacao-bdgd","type":'outlined',"selectedCompany":_vm.companyIdEdit},on:{"companySelected":_vm.companySelected}})],1)],1):_vm._e(),_c('v-row',{staticClass:"mx-auto"},[_c('v-col',{attrs:{"cols":"12"}},[_c('input-date',{attrs:{"label":"Mês dos dados","selectedDate":_vm.dataBaseEdit},on:{"dataSelected":_vm.dataSelected}})],1)],1)],1),_c('v-row',{attrs:{"id":"row-btn-save"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mt-n5 mr-0",staticStyle:{"float":"right"},attrs:{"id":"btn-save-relatorio","color":"primary","min-width":"100","loading":_vm.loading,"disabled":invalid || _vm.invalido},on:{"click":function($event){return _vm.salvar()}}},[_vm._v(" Salvar ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }